import React, {useState} from 'react'
import axios from 'axios'
import Obfuscate from 'react-obfuscate';
import Input from "../components/Input"
import { Hero } from "../components/Hero/Hero"
import SEO from "../components/SEO"
import heroImage from '../images/hero/contact.jpg'
import linkedIn from '../images/linkedin.png'
import '../styles.scss'

const ContactPage = () => {
  const [formStatus, setFormStatus] = useState('Submit Enquiry')

  const handleSubmitForm = (event) => {
    event.preventDefault();
    setFormStatus('Sending...')
    
    const data = new FormData(event.target);
    let formData = {
      name: data.get('name'),
      last_name: data.get('last_name'),
      email: data.get('email'),
      message: data.get('enquiry')
    }

    axios.post('https://montanaconcept.com/mail/sendMail.php', formData)
      .then((res) => {
        setFormStatus('Sent')
        setTimeout(() => { setFormStatus('Submit Enquiry') }, 5000)
      })
      .catch((err) => {
        setFormStatus('Not Sent? Try Again.')
      })
  }

  return (
    <>
      <SEO title="Contact" />
        <Hero page='contact' backgroundImage={heroImage}>
        <div className='contact-details'>
          <h1>Contact Us</h1>
          <form method='post' onSubmit={handleSubmitForm}>
            <Input name='name' placeholder='Name' reset={formStatus==='Sent' && true} required />
            <Input name='email' type='email' placeholder='Email' reset={formStatus==='Sent' && true} required />
            <Input name='enquiry' type='textarea' placeholder='Enquiry' reset={formStatus==='Sent' && true} required />
            <Input name='last_name' placeholder='Last Name' honeypot />
            <button type='submit'>{formStatus}</button>
          </form>
          <address>
            Keith Sammels
            <Obfuscate email='keith.sammels@montanaconcept.com' subject='Website Enquiry' />
            <Obfuscate tel='+44 (0) 7884233131' />
            <a href='http://linkedin.com/in/keith-j-sammels-fcim-5539b4146' target='_blank' rel="noreferrer">
              <img src={linkedIn} alt='LinkedIn profile' width='75' style={{ marginTop: '.6rem' }} />
            </a>
          </address>
          <address>
            NicOlA Sammels
            <Obfuscate email='nicola.sammels@montanaconcept.com' subject='Website Enquiry' />
            <Obfuscate tel='+44 (0) 7899920212' />
          </address>
        </div>
      </Hero>
      </>
  )
}

export default ContactPage