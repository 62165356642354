import React, { useState, useEffect } from "react"

const Input = ({ name, type='text', placeholder, reset, required, honeypot}) => {
  const [val, setVal] = useState('')
  useEffect(() => {
    reset && setVal('')
  }, [reset])

  const handleUpdateValue = value => setVal(value)

  return (
    <label>
      <span className='hidden'>{name}</span>
      {type === 'textarea' ? (
        <textarea {...{placeholder, name, required}} rows='4' value={val} onChange={e => handleUpdateValue(e.target.value)} />
      ) : (
        <input
        {...{placeholder, name, type, required}}
        className={honeypot ? 'form__input' : ''}
        value={val}
        onChange={e => handleUpdateValue(e.target.value)} />
      )
      }
    </label>
  )
}

export default Input